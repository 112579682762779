import { allAdAccounts } from "@/api/adManagement";
export default {
    methods: {
        allAdAccounts() {
            return allAdAccounts().then(async (res) => {
                let arr = [];
                if (res.code == 0 && res.data && res.data.validThirdLoginUsers) {
                    arr = _.cloneDeep(res.data.validThirdLoginUsers);
                    arr.forEach(
                        (item) =>
                        (item.name = `${item.thirdUser.displayName
                            ? item.thirdUser.displayName
                            : item.thirdUser.thirdUserName
                            }（ ${item.thirdUser.thirdUserEmail}）`)
                    );
                }
                await this.$store.dispatch("batchAccount/setAccountsOptions", {
                    accountsOptions: arr,
                });
                return res;
            });
        }
    }
}